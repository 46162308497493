function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
        if (window.innerWidth < 992) {
            if (entry.intersectionRatio >= 0.5) {
                entry.target.classList.add("hover");
            } else {
                entry.target.classList.remove("hover");
            }
        }
    });
}

function observeProduct() {
    const products = document.querySelectorAll(".product");
    if (products.length > 0) {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5,
        });
        products.forEach((product) => observer.observe(product));
    }
}

observeProduct();
