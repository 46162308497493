/* -------------------------------------------------------------------------- */
/*                                  Minicart                                  */
/* -------------------------------------------------------------------------- */
document.addEventListener("DOMContentLoaded", function () {
  jQuery(function ($) {
    $(".mini-cart-btn , .mini-cart-btn-mobile").on("click", function (e) {
      e.preventDefault();

      var atl_header_minicart = $("#atl-header-minicart");
      atl_header_minicart.toggleClass("active");

      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        dataType: "html",
        data: {
          action: "atl_mini_cart",
        },
        beforeSend: function (res) {
          atl_header_minicart.addClass("loading");
        },
        success: function (res) {
          setTimeout(() => {
            atl_header_minicart.removeClass("loading");
          }, 300);

          setTimeout(() => {
            $("#atl-header-minicart").empty();
            // $("#mobiCart").empty();
            $("#atl-header-minicart").html(res);
            //   $("#mobiCart").html(res);
          }, 350);

          //   console.log("minicart loaded!");
        },
      });
    });

    jQuery.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      dataType: "html",
      data: {
        action: "atl_mini_cart_count",
      },
      beforeSend: function (res) {
        // console.log("counting minicart items...");
      },
      success: function (res) {
        // console.log("minicart items loaded: " + res);
        $(document.body).trigger("wc_fragment_refresh");
        $("#mini-cart-item-count").addClass("counted");
        $("#mini-cart-item-count-number").html(res);
      },
    });
  });
});
