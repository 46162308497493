document.addEventListener("DOMContentLoaded", function () {
  const quoteButton = document.getElementById("offerte-aanvragen");
  const quoteButtonGhost = document.getElementById("offerte-aanvragen-ghost");

  // const quoteButtonMobile = document.getElementById("pdp-anchor-link");
  const quoteButtonMobileGhost = document.getElementById(
    "pdp-anchor-link-ghost"
  );

  const gFormButton = document.getElementById("gform_submit_button_2");
  const gFormFooter = document.querySelector(".gform_footer");

  const noticeModal = document.getElementById("product-selection");

  // Select all UL elements within the table with the class "variations"
  const variationLists = document.querySelectorAll(".variations ul");

  const element = document.getElementById("select-bike");

  quoteButtonGhost?.addEventListener("click", function (e) {
    e.preventDefault();
    noticeModal.classList.add("active");

    setTimeout(() => {
      noticeModal.classList.remove("active");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 2500);
  });

  quoteButtonMobileGhost?.addEventListener("click", function (e) {
    e.preventDefault();
    noticeModal.classList.add("active");

    setTimeout(() => {
      noticeModal.classList.remove("active");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 2500);
  });

  function checkVariationsSelected() {
    let allSelected = true;
    variationLists.forEach(function (ul) {
      // Check if at least one li in this ul has aria-checked="true"
      const isSelected = ul.querySelector('li[aria-checked="true"]');
      if (!isSelected) {
        allSelected = false;
      }
    });

    if (allSelected) {
      quoteButtonGhost.remove();
      quoteButtonMobileGhost.remove();
      gFormButton.removeAttribute("disabled");
      gFormFooter.style.pointerEvents = "all";
      // console.log("All options chosen");
    } else {
      // console.log("Not all options chosen");
      gFormButton.setAttribute("disabled", "disabled");
      gFormFooter.style.pointerEvents = "none";
    }
  }

  if (quoteButton && variationLists.length > 0) {
    checkVariationsSelected();
    // Add a mutation observer to watch for changes in the UL elements
    const observer = new MutationObserver(checkVariationsSelected);
    variationLists.forEach((ul) => {
      observer.observe(ul, {
        attributes: true,
        subtree: true,
        attributeFilter: ["aria-checked"],
      });
    });
  }
});
