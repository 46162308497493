function searchFunc(btn, form, field) {
  var searchBtn = document.querySelector(btn);
  var searchForm = document.querySelector(form);
  var input = document.getElementById(field);
  var active = "search-is-active";

  document.addEventListener("keyup", function (event) {
    if (event.code === "Escape") {
      if (searchBtn.classList.contains("active-btn")) {
        searchBtn.classList.remove("active-btn");
        closer.classList.remove("search-closer__active");
        searchForm.classList.remove(active);
        input.value = "";
      }
    }

    if (event.code === "Enter") {
      params = new URLSearchParams({
        s: input.value,
      });
      if (
        searchBtn.classList.contains("active-btn") &&
        input.value.length >= 3
      ) {
        window.open("/?" + params.toString(), "_self");
      }
    }
  });

  document.onkeydown = function (e) {
    e = e || window.event;
    var isEnter = false;
    if ("key" in e) {
      isEnter = e.key === "Enter";
    } else {
      isEnter = e.code === 13;
    }

    if (
      isEnter &&
      input.value != "" &&
      input.length >= 3 &&
      searchBtn.classList.contains("active-btn")
    ) {
      params = new URLSearchParams({
        s: input.value,
      });
      window.open("/?" + params.toString(), "_self");
    }
  };
}

/* -------------------------------------------------------------------------- */
/*                               Desktop search                               */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", function () {
  var searchToggleDesktop = document.getElementById("searchToggleDesktop");
  var searchFormDesktop = document.getElementById("searchFormDesktop");

  if (searchToggleDesktop) {
    searchToggleDesktop.addEventListener("click", function (event) {
      event.preventDefault();
      searchFormDesktop.classList.toggle("active");
      // Assuming the input is of type 'text'
      var input = searchFormDesktop.querySelector('input[type="search"]');
      if (input) {
        input.focus();
      }
    });
  }
});
/* -------------------------------------------------------------------------- */
/*                                Mobile search                               */
/* -------------------------------------------------------------------------- */

// document.addEventListener("DOMContentLoaded", function () {
//   var searchToggleMobile = document.getElementById("searchToggleMobile");
//   var searchFormMobile = document.getElementById("searchFormMobile");

//   if (searchToggleMobile) {
//     searchToggleMobile.addEventListener("click", function () {
//       searchFormMobile.classList.toggle("active");
//     });
//   }
// });
