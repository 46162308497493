document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("single-product").length >= 1) {
    /* -------------------------------------------------------------------------- */
    /*                      Sync color with splide selection                      */
    /* -------------------------------------------------------------------------- */

    // Get all elements with the class "color-variable-item"
    const colorItems = document.querySelectorAll(".color-variable-item");

    // Iterate over the color items
    colorItems.forEach((item) => {
      // Add a click event listener to each item
      item.addEventListener("click", function () {
        // Get the "data-title" attribute of the current item
        const title = item.getAttribute("data-title");

        // Get all elements with the attribute "data-color-name"
        const colorNames = document.querySelectorAll("[data-color-name]");

        // Iterate over the color names
        colorNames.forEach((colorNameElement) => {
          // Get the "data-color-name" attribute of the current color name element
          const colorName = colorNameElement.getAttribute("data-color-name");

          // If the title matches the color name
          if (title === colorName) {
            // Click on the color name element
            colorNameElement.click();

            // Get the image inside the color name element
            const image = colorNameElement.querySelector("img");

            // If the image exists
            if (image) {
              // Get the src attribute of the image
              const src = image.getAttribute("src");

              // Get the input with the ID "input_2_19"
              const input = document.getElementById("input_2_19");

              // If the input exists
              if (input) {
                // Set the src as the value of the input
                input.value = src;
              }
            }
          }
        });
      });
    });

    /* -------------------------------------------------------------------------- */
    /*      Changes the svg to an active state and also removes active state      */
    /* -------------------------------------------------------------------------- */

    jQuery(document).ready(function ($) {
      function rgbToHex(rgb) {
        const result = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/.exec(rgb);
        return result
          ? "#" +
              ("0" + parseInt(result[1], 10).toString(16)).slice(-2) +
              ("0" + parseInt(result[2], 10).toString(16)).slice(-2) +
              ("0" + parseInt(result[3], 10).toString(16)).slice(-2)
          : null;
      }

      $(".color-variable-items-wrapper .variable-item-contents").each(
        function () {
          const backgroundColor = $(this)
            .find(".variable-item-span-color")
            .css("background-color");
          const hexColor = rgbToHex(backgroundColor);

          var svg = `<div class="custom-color-select" data-state="original">
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13" viewBox="0 0 21 13" fill="none">
          <path d="M0.968316 12.2158L0.908733 12.3178H1.02686H12.9862H13.0246L13.0443 12.2848L20.2308 0.284833L20.2922 0.182203H20.1726H12.5508C9.73136 0.182203 7.12382 1.6788 5.70165 4.11327L0.968316 12.2158Z" fill="${hexColor}" stroke="#999999" stroke-width="1"></path>
      </svg>
      </div>`;

          $(this).append(svg);
        }
      );

      $(".color-variable-item").click(function () {
        const hexColor = rgbToHex(
          $(this).find(".variable-item-span-color").css("background-color")
        );

        var newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="13" viewBox="0 0 21 13" fill="none">
    <path d="M0.968316 12.2158L0.908733 12.3178H1.02686H12.9862H13.0246L13.0443 12.2848L20.2308 0.284833L20.2922 0.182203H20.1726H12.5508C9.73136 0.182203 7.12382 1.6788 5.70165 4.11327L0.968316 12.2158Z" fill="${hexColor}" stroke="#333333" stroke-width="1"></path>
</svg>`;

        // Revert all other items back to original SVG and remove 'selected' class
        $(".color-variable-item")
          .not(this)
          .removeClass("selected")
          .find(".custom-color-select")
          .each(function () {
            if ($(this).data("state") === "new") {
              $(this)
                .html($(this).data("original-svg"))
                .data("state", "original");
            }
          });

        // Toggle SVG for clicked item and toggle 'selected' class
        var customColorSelect = $(this).find(".custom-color-select");
        if (customColorSelect.data("state") === "original") {
          $(this).addClass("selected");
          customColorSelect.data("original-svg", customColorSelect.html());
          customColorSelect.html(newSvg).data("state", "new");
        } else {
          $(this).removeClass("selected");
          customColorSelect
            .html(customColorSelect.data("original-svg"))
            .data("state", "original");
        }

        const dataValue = $(this).data("value");
        // console.log(dataValue);
        $("#pa_kleur option").each(function () {
          if ($(this).val() === dataValue) {
            $(this).attr("selected", "selected");
          } else {
            $(this).removeAttr("selected");
          }
        });

        $("#pa_kleur").trigger("change");
      });
    });
  }
});
