import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { URLHash } from "@splidejs/splide-extension-url-hash";

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("block-slider__splide").length >= 1) {
    var splide = new Splide(".block-slider__splide", {
      type: "loop",
      drag: "free",
      focus: 1,
      trimSpace: false,
      pagination: false,
      arrows: false,
      perPage: 8,
      gap: 24,
      perMove: 1,
      autoScroll: {
        speed: 0.4,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        992: {
          perPage: 6,
        },
        767: {
          perPage: 4,
        },
      },
    });
    // splide.mount();
    splide.mount({ AutoScroll });
  }

  if (document.getElementsByClassName("block-intro__splide").length >= 1) {
    var splide = new Splide(".block-intro__splide", {
      type: "loop",
      drag: "free",
      focus: 1,
      trimSpace: false,
      pagination: false,
      arrows: false,
      perPage: 9,
      perMove: 1,
      autoScroll: {
        speed: 0.4,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        992: {
          perPage: 6,
        },
        767: {
          perPage: 3,
        },
      },
    });
    // splide.mount();
    splide.mount({ AutoScroll });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("pdp-slider").length >= 1) {
    var thumbnails = new Splide(".sub-slider", {
      fixedWidth: 90,
      fixedHeight: 75,
      gap: 42,
      rewind: false,
      type: "slide",
      pagination: false,
      isNavigation: true,
      arrows: false,
      breakpoints: {
        768: {
          gap: 12,
        },
        1024: {
          gap: 24,
        },
      },
    });

    var splide = new Splide(".main-slider", {
      perPage: 1,
      pagination: false,
      type: "fade",
      rewind: true,
      duration: 400,
      autoHeight: true,
      // height: "20rem",
    });

    splide.sync(thumbnails);
    splide.mount();
    thumbnails.mount();

    var thummies: HTMLCollection =
      document.getElementsByClassName("sub-slider__slide")!;
    if (thummies.length >= 1) {
      var i: number = 0;
      for (i; i < thummies.length; i++) {
        thummies[i].addEventListener("click", function (e) {
          e.preventDefault();
        });
      }
    }
  }
});

/* -------------------------------------------------------------------------- */
/*                             Radom border radius                            */
/* -------------------------------------------------------------------------- */
document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("splide-team")) {
    var slides = document.querySelectorAll(".splide-team .splide__slide");
    slides.forEach(function (slide) {
      var randomInt = Math.floor(Math.random() * 3) + 1;
      switch (randomInt) {
        case 0:
          slide.style.borderTopRightRadius = "40px";
          break;
        case 1:
          slide.style.borderTopLeftRadius = "40px";
          break;
        case 2:
          slide.style.borderBottomRightRadius = "40px";
          break;
        case 3:
          slide.style.borderBottomLeftRadius = "40px";
          break;
      }
    });
  }

  if (document.getElementsByClassName("hd-content__gallery")) {
    var items = document.querySelectorAll(
      ".hd-content__gallery .hd-content__gallery--item img"
    );
    items.forEach(function (item) {
      var randomInt = Math.floor(Math.random() * 3) + 1;
      switch (randomInt) {
        case 0:
          item.style.borderTopRightRadius = "30px";
          break;
        case 1:
          item.style.borderTopLeftRadius = "30px";
          break;
        case 2:
          item.style.borderBottomRightRadius = "30px";
          break;
        case 3:
          item.style.borderBottomLeftRadius = "30px";
          break;
      }
    });
  }
});
