document.addEventListener("DOMContentLoaded", function () {
    var item1 = document.getElementsByClassName("item-1");
    var item2 = document.getElementsByClassName("item-2");
    var item3 = document.getElementsByClassName("item-3");

    for (var i = 0; i < item2.length; i++) {
        var rect = item2[i].getBoundingClientRect();
        var offset = rect.top / window.innerHeight;
        item2[i].style.transform = 'translate(-160px, ' + (95 - (offset * -200)) + 'px)';
    }

    for (var i = 0; i < item3.length; i++) {
        var rect = item3[i].getBoundingClientRect();
        var offset = rect.top / window.innerHeight;
        item3[i].style.transform = 'translate(160px, ' + (85 - (offset * -100)) + 'px)';
    }
    window.addEventListener("scroll", function () {
        if (window.innerWidth < 992) {
            return;
        } else {
            var scroll = window.scrollY / 5;
        }
        for (var i = 0; i < item2.length; i++) {
            var rect = item2[i].getBoundingClientRect();
            var offset = rect.top / window.innerHeight;
            item2[i].style.transform = 'translate(-160px, ' + (60 - (offset * -200)) + 'px)';
        }
    
        for (var i = 0; i < item3.length; i++) {
            var rect = item3[i].getBoundingClientRect();
            var offset = rect.top / window.innerHeight;
            item3[i].style.transform = 'translate(160px, ' + (85 - (offset * -100)) + 'px)';
        }

    });

});