function addClickEventListenerToBapfElement(bapfElementId) {
  const bapfElement = document.getElementById(bapfElementId);

  if (bapfElement) {
    const bapfBodyElement = bapfElement.querySelector(".bapf_body");
    const listItemElements = bapfBodyElement.querySelectorAll("li");
    const listItemHeight = 41; // Assuming each list item is 20px tall
    const totalHeight = listItemHeight * listItemElements.length;

    bapfBodyElement.style.height = totalHeight + "px"; // Set initial height to totalHeight

    bapfElement.addEventListener("click", function () {
      // Check the current height of the element
      const currentHeight = parseFloat(bapfBodyElement.style.height);

      // Toggle the height between 0 and its total height
      if (currentHeight === 0) {
        bapfBodyElement.style.height = totalHeight + "px";
      } else {
        bapfBodyElement.style.height = "0px";
      }
    });
  }
}

// Call the function for each bapf element
addClickEventListenerToBapfElement("bapf_1");
addClickEventListenerToBapfElement("bapf_2");
addClickEventListenerToBapfElement("bapf_3");

/* -------------------------------------------------------------------------- */
/*          Enables user to open and close the categories or filters          */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", function () {
  var acc = document.getElementById("mobile-cats");
  var mediaQuery = "(max-width: 992px)"; // Changed media query to 992px

  if (acc) {
    acc.addEventListener("click", function (e) {
      e.preventDefault();
      this.classList.toggle("atl__title--active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight !== "0px") {
        panel.style.maxHeight = "0px";
      } else {
        panel.style.maxHeight = 5000 + "px";
      }
    });

    if (window.matchMedia(mediaQuery).matches === true) {
      if (acc.classList.contains("atl__title--active")) {
        acc.nextElementSibling.style.maxHeight = "0px";
        acc.classList.remove("atl__title--active");
      }
      var shopLayoutSide = document.querySelector(".shop-layout__side");
      shopLayoutSide.style.maxHeight = "0px";
    }
  }
});
