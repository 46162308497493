document.addEventListener("DOMContentLoaded", () => {
  const planTestdrive = document.getElementById("plan-testdrive");
  const productTestdrive = document.getElementById("product-testdrive");
  const productTestdriveform = document.getElementById(
    "product-testdrive-form"
  );
  const closeModal = document.getElementById("close-modal");

  let planTestdriveClicked = false;

  if (planTestdrive && productTestdrive) {
    planTestdrive.addEventListener("click", function (event) {
      event.stopPropagation();
      productTestdrive.classList.toggle("active");
      planTestdriveClicked = true;

      // Toggle overflow style on body
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    });

    closeModal.addEventListener("click", function () {
      productTestdrive.classList.remove("active");
      document.body.style.overflow = "";
    });
  }

  document.body.addEventListener("click", function (event) {
    if (
      planTestdriveClicked &&
      productTestdriveform &&
      !productTestdriveform.contains(event.target as Node) &&
      planTestdrive !== event.target
    ) {
      productTestdrive.classList.remove("active");
      document.body.style.overflow = "";
      planTestdriveClicked = false;
    }
  });
});
