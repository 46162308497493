document.addEventListener("DOMContentLoaded", function () {
  if (document.body.classList.contains("single-product")) {
    setTimeout(() => {
      const variationItemsWrappers = document.querySelectorAll(
        ".woo-variation-items-wrapper"
      );

      variationItemsWrappers.forEach((wrapper, index) => {
        // Find the ul element inside the wrapper
        const ul = wrapper.querySelector("ul");

        if (ul) {
          // Check if the ul has only one li element
          const liElements = ul.querySelectorAll("li");

          if (liElements.length === 1) {
            // Simulate a click on the single li element
            liElements[0].click();
          }
        }
      });
    }, 1500);
  }
});
