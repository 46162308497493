//custom code
import "./custom/mobile-menu";
import "./custom/scroller";
import "./custom/torus-animations";
import "./custom/search";
// import "./custom/cookie";
import "./custom/header-cycle";
import "./custom/splide";
import "./custom/custom-parallax";
import "./custom/minicart";
import "./custom/product-tabs";
import "./custom/update-ean";
import "./custom/pdp-modal";
import "./custom/custom-color-select";
import "./custom/tweak_bapf_filters";
import "./custom/archive-hover";
import "./custom/torus_tooltips";
import "./custom/pdp-button-logic";
import "./custom/pdp_auto_select";

//libraries
import "./lib/fslightbox";
