document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelectorAll(".product-tabs-section__tab-cats--list__item--link")
    .forEach(function (tabLink) {
      tabLink.addEventListener("click", function (e) {
        e.preventDefault();

        // Remove active class from all links
        document
          .querySelectorAll(".product-tabs-section__tab-cats--list__item--link")
          .forEach(function (link) {
            link.classList.remove("active");
          });

        // Add active class to clicked link
        this.classList.add("active");

        // Remove active class from all tabs
        document
          .querySelectorAll(
            ".product-tabs-section__tab-cats--description, .product-tabs-section__tab-cats--specifications, .product-tabs-section__tab-cats--warranty_maintenance, .product-tabs-section__tab-cats--lease"
          )
          .forEach(function (tab) {
            tab.classList.remove("active");
          });

        // Add active class to corresponding tab
        var tabId = this.getAttribute("href").replace("-link", "-content");
        var tab = document.querySelector("#" + tabId);
        if (tab) {
          tab.classList.add("active");
        }
      });
    });
});
